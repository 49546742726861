import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PeriodicVisitDetailsModal.css';  // Crea un archivo CSS específico para este modal
import api from '../../api/axiosConfig';

const PeriodicVisitDetailsModal = ({ onClose, establishmentId }) => {
  const [establishment, setEstablishment] = useState(null);
  const [nextVisit, setNextVisit] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);

  useEffect(() => {
    const fetchEstablishmentDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get(`/api/establishments/${establishmentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          setEstablishment(response.data);
        }
      } catch (error) {
        console.error('Error al obtener los detalles del establecimiento:', error);
      }
    };

    fetchEstablishmentDetails();
  }, [establishmentId]);

  const fetchNextVisit = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/establishments/${establishmentId}/nextVisit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.nextVisit) {
        setNextVisit(response.data.nextVisit);
        setDaysLeft(response.data.daysLeft);
      } else {
        setNextVisit(null);
        setDaysLeft(null);
      }
    } catch (error) {
      console.error('Error al obtener la próxima visita:', error);
    }
  };

  useEffect(() => {
    fetchNextVisit();
  }, [establishmentId]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="periodic-visit-modal-container">
        <div className="periodic-visit-header">
          <h2>Detalles de la Visita Periódica</h2>
          <button className="close-button" onClick={onClose} aria-label="Cerrar">Cerrar</button>
        </div>
        <div className="periodic-visit-body">
          {establishment ? (
            <>
              <p><strong>Establecimiento:</strong> {establishment.name}</p>
              <p><strong>Dirección:</strong> {establishment.address}, {establishment.city}, {establishment.province}</p>
              <a
                href={`dashboard/establecimiento/${establishment._id}/visits`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver detalles del establecimiento
              </a>
            </>
          ) : (
            <p>Cargando detalles del establecimiento...</p>
          )}
          <div className="next-visit-info">
            <h2>Fecha de la Próxima Visita:</h2>
            <p>{nextVisit ? getFormattedDate(nextVisit) : 'No disponible'}</p>
            <div className="days-remaining">
              <h2>Días hasta la próxima visita:</h2>
              <div className="days-box">{daysLeft ?? '-'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PeriodicVisitDetailsModal.propTypes = {
  visit: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  establishmentId: PropTypes.string.isRequired,
};

export default PeriodicVisitDetailsModal;
