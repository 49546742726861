// PDFViewModal.jsx
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './PDFViewModal.css';
import api from '../../api/axiosConfig';

const PDFViewModal = ({ form, onClose }) => {
  const [companyLogo, setCompanyLogo] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [userName, setUserName] = useState('N/A');
  const [licenseNumber, setLicenseNumber] = useState('N/A');
  const blankImageBase64 =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wIAAgEBAWn+hdcAAAAASUVORK5CYII=';

  // Fetch user profile on component mount
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setCompanyLogo(userData.companyLogo || null);
        setSignatureImage(userData.signatureImage || null);
        setUserName(`${userData.name} ${userData.surname}`);
        setLicenseNumber(userData.licenseNumber || 'N/A');
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  // Utility to convert an image URL to Base64
  const loadImageAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;

      img.onload = () => {
        try {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        } catch (error) {
          console.error('Error processing image:', error);
          reject(error);
        }
      };

      img.onerror = (error) => {
        console.error(`Error loading image from ${url}:`, error);
        reject(error);
      };
    });
  };

  // Handle PDF generation and download
  const handleDownloadPDF = async () => {
    const doc = new jsPDF();
    doc.setFont('helvetica', 'normal');

    // Inicializamos con imágenes en blanco
    let logoBase64 = blankImageBase64;
    let signatureBase64 = blankImageBase64;

    try {
      // Intentar cargar el logo
      if (companyLogo) {
        logoBase64 = await loadImageAsBase64(companyLogo);
      }
    } catch (error) {
      console.warn('Logo could not be loaded, using blank image:', error);
    }

    try {
      // Intentar cargar la firma
      if (signatureImage) {
        signatureBase64 = await loadImageAsBase64(signatureImage);
      }
    } catch (error) {
      console.warn('Signature could not be loaded, using blank image:', error);
    }

    try {
      // Contenido principal del PDF
      doc.setFontSize(18);
      doc.text(form.title, 10, 20);
      doc.setFontSize(12);
      doc.text(form.description || 'Sin descripción', 10, 30);

      const tableData = form.fields.map((field) => [field.label || field.name, field.value]);
      doc.autoTable({
        head: [['Campo', 'Valor']],
        body: tableData,
        startY: 40,
      });

      const finalY = doc.lastAutoTable.finalY + 10;

      // Agregar el logo
      doc.addImage(logoBase64, 'PNG', 10, 10, 50, 20);

      // Agregar la firma
      doc.addImage(signatureBase64, 'PNG', 140, finalY, 50, 30);
      doc.text(`Nombre: ${userName}`, 140, finalY + 35);
      doc.text(`Número de matrícula: ${licenseNumber}`, 140, finalY + 45);

      // Guardar el PDF
      const creationDate = new Date(form.createdAt).toLocaleDateString('es-ES');
      doc.save(`${form.title} ${creationDate}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Ocurrió un error al generar el PDF. Por favor, intente nuevamente.');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="pdf-view-modal-container">
        <div className="pdf-view-modal-header">
          <h2>{form.title}</h2>
          <button className="close-button" onClick={onClose} aria-label="Cerrar">
            ×
          </button>
        </div>
        <div className="pdf-view-modal-body">
          <p>{form.description || 'Sin descripción'}</p>
          <table className="form-fields">
            <thead>
              <tr>
                <th>Campo</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {form.fields.map((field) => (
                <tr key={field._id}>
                  <td>{field.label || field.name}</td>
                  <td>{field.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pdf-view-modal-footer">
          <button className="download-button" onClick={handleDownloadPDF}>
            Descargar PDF
          </button>
        </div>
      </div>
    </div>
  );
};

PDFViewModal.propTypes = {
  form: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.null]),
      })
    ).isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PDFViewModal;