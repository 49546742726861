import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PlanExpiredModal.css';
import PropTypes from 'prop-types';

const PlanExpiredModal = ({ userName }) => {
    const [showModal, setShowModal] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!showModal) {
            navigate('/'); // Redirige al Home cuando se cierra el modal
        }
    }, [showModal, navigate]);

    const handleButtonClick = () => {
        setShowModal(false);
        setTimeout(() => {
            navigate('/', { state: { scrollToSection: 'pricing' } });
        }, 500);
    };

    if (!showModal) return null;

    return (
        <div className="plan-expired-modal-overlay">
            <div className="plan-expired-modal-container">
                <div className="plan-expired-header">
                    <h2>Hola, {userName}</h2>
                </div>
                <div className="plan-expired-body">
                    <p>
                        Lamentablemente tu plan ha caducado, pero no te preocupes, todos tus
                        datos, personalizaciones y formularios están guardados. Haz clic en
                        el siguiente botón para seleccionar un plan y proceder con la compra.
                    </p>
                </div>
                <div className="plan-expired-footer">
                    <button
                        onClick={handleButtonClick}
                        className="plan-expired-button"
                    >
                        Seleccionar Plan
                    </button>
                    <p className="thanks-message">
                        Gracias, <br /> Equipo de BioSafeApp
                    </p>
                </div>
            </div>
        </div>
    );
};

PlanExpiredModal.propTypes = {
    userName: PropTypes.string.isRequired,
};

export default PlanExpiredModal;
