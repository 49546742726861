import React from 'react';
import PropTypes from 'prop-types';
import { FaClock } from 'react-icons/fa';
import './WorkingHoursDisplay.css';
import noScheduleImage from '../../assets/no-schedule.jpeg'; // Importamos la imagen

const WorkingHoursDisplay = ({ workingHours, establishmentId, showGoToEstablishmentButton }) => {
  const today = new Date().toLocaleDateString('es-ES', { weekday: 'long' }).toLowerCase();

  // Caso 1: No hay ningún horario configurado
  const isCompletelyEmpty = workingHours.length === 0;

  // Caso 2: Todos los días tienen intervals vacíos
  const allDaysClosed = workingHours.every((day) => day.intervals.length === 0);

  const handleGoToEstablishment = () => {
    window.open(`/dashboard/establecimiento/${establishmentId}/details`, "_blank");
  };

  return (
    <div className="working-hours-container" data-testid="working-hours-container">
      <h2 data-testid="working-hours-title">
        <FaClock className="clock-icon" /> Horarios de Atención
      </h2>

      {/* Si no hay horarios o todos están cerrados, muestra la imagen */}
      {isCompletelyEmpty || allDaysClosed ? (
        <div className="no-schedule-container" data-testid="no-schedule">
          <img
            src={noScheduleImage}
            alt="Sin horarios configurados"
            className="no-schedule-image"
          />
          <p className="no-schedule-message">
            No hay horarios configurados para este establecimiento.
          </p>
          {showGoToEstablishmentButton && (
            <button
              onClick={handleGoToEstablishment}
              className="go-to-establishment-btn"
            >
              Ir a Establecimiento
            </button>
          )}
        </div>
      ) : (
        <div className="days-container">
          {workingHours.map((day) => {
            const isToday = day.day.toLowerCase() === today;
            return (
              <div key={day.day} className={`day-row ${isToday ? 'today-row' : ''}`}>
                <span className="day-name">{day.day}</span>
                {day.intervals && day.intervals.length > 0 ? (
                  <span className="hours">
                    {day.intervals.map((interval, index) => (
                      <span key={index}>
                        {interval.start} - {interval.end}
                        {index < day.intervals.length - 1 && ', '}
                      </span>
                    ))}
                  </span>
                ) : (
                  <span className="closed">Cerrado</span>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};


WorkingHoursDisplay.propTypes = {
  workingHours: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      intervals: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.string.isRequired,
          end: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  establishmentId: PropTypes.string.isRequired,
  showGoToEstablishmentButton: PropTypes.bool, // Nueva prop
};

export default WorkingHoursDisplay;