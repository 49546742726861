import React, { useEffect, useState } from 'react';
import api from '../../api/axiosConfig';
import './FreePlanReminderBanner.css';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const FreePlanBanner = ({ planType, planEndDate }) => {
    const [daysLeft, setDaysLeft] = useState(0);
    const [userName, setUserName] = useState('Usuario'); // Por defecto, un nombre genérico
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (planEndDate) {
            const today = new Date();
            const endDate = new Date(planEndDate);
            const diffInTime = endDate - today;
            const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
            setDaysLeft(diffInDays);
        }
    }, [planEndDate]);

    const handleButtonClick = () => {
        setTimeout(() => {
            navigate('/', { state: { scrollToSection: 'pricing' } }); // Desplaza a la sección de precios
        }, 500); // Retraso antes del desplazamiento
    };

    useEffect(() => {
        const fetchUserName = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await api.get('/api/users/profile', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const { name } = response.data;
                setUserName(name || 'Usuario');
            } catch (error) {
                console.error('Error fetching user name:', error);
            }
        };
        fetchUserName();
    }, []);

    if (planType !== 1) return null;

    const toggleBanner = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <div className="free-plan-banner">
            <div className="banner-header">
                <span className="banner-text">
                    👋 Hola, {userName}, estás en el Plan Gratuito. Te quedan <strong>{daysLeft} días</strong> para seguir disfrutando este plan.
                </span>
                <button className="toggle-button" onClick={toggleBanner}>
                    Más info
                </button>
            </div>
            <div className={`banner-body ${isExpanded ? 'expanded' : ''}`}>
                <p>
                    Cambia al <strong>Plan Premium</strong> para desbloquear:
                    <ul>
                        <li>Generación de reportes avanzados</li>
                        <li>Más almacenamiento para tus datos</li>
                        <li>Soporte técnico prioritario</li>
                        <li>Acceso a herramientas exclusivas</li>
                    </ul>
                </p>
                <button
                    onClick={handleButtonClick}
                    className="select-plan-button"
                >
                    Seleccionar Plan
                </button>
                {daysLeft <= 3 && (
                    <p className="urgent-message">
                        <strong>¡Quedan pocos días para que expire tu plan gratuito! Renueva ahora para evitar interrupciones.</strong>
                    </p>
                )}
            </div>
        </div>
    );
};

FreePlanBanner.propTypes = {
    planType: PropTypes.number.isRequired,
    planEndDate: PropTypes.string,
};

export default FreePlanBanner;