import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import EstablishmentForm from '../forms/EstablishmentForm';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import './AddEstablishmentButton.css';

const AddEstablishmentButton = ({ refreshList, initialData, isEditing, onSave, onCancel }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (isEditing && initialData) {
      setIsPopupOpen(true);
    }
  }, [isEditing, initialData]);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => {
    setIsPopupOpen(false);
    onCancel();
  };

  const handleFormSubmit = async (data) => {
    if (isEditing) {
      await onSave(data);
      closePopup();
    } else {
      try {
        const token = localStorage.getItem('authToken');
        await api.post('/api/establishments', data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success('Establecimiento creado con éxito');
        refreshList();
        closePopup();
      } catch (error) {
        console.error('Error al crear el establecimiento:', error);
        toast.error('Error al crear el establecimiento');
      }
    }
  };

  return (
    <div className="add-establishment-button-container">
      {!isEditing && (
        <button
          data-testid="open-create-establishment-btn"
          className="add-button"
          onClick={openPopup}
        >
          <span className="plus-icon">+</span> Crear Establecimiento
        </button>
      )}

      <Modal open={isPopupOpen} onClose={closePopup} className="add-establishment-modal">
        <div className="add-establishment-modal-content" onClick={(e) => e.stopPropagation()}>
          <h2>{isEditing ? 'Editar Establecimiento' : 'Crear Establecimiento'}</h2>
          <EstablishmentForm
            onSubmit={handleFormSubmit}
            initialData={initialData}
            isEditing={isEditing}
          />
          <div className="add-establishment-actions">
            <button className="cancel-button" onClick={closePopup}>
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

AddEstablishmentButton.propTypes = {
  refreshList: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

AddEstablishmentButton.defaultProps = {
  initialData: null,
  isEditing: false,
  onSave: () => {},
  onCancel: () => {},
};

export default AddEstablishmentButton