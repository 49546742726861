import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useParams } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '../../screen/Establishment.css';
import EstablishmentForm from '../forms/EstablishmentForm';
import api from '../../api/axiosConfig';
import ContactModal from './ContactModal'; // Importa el modal de contactos
import { FaPhone, FaWhatsapp, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import locationPin from '../../assets/locationpin.png'; // Ajusta el path según la estructura de tus carpetas

const customIcon = new L.Icon({
  iconUrl: locationPin,          // Usa la imagen importada
  iconSize: [32, 32],            // Tamaño del ícono en píxeles (ajústalo según tus necesidades)
  iconAnchor: [16, 32],          // El punto que se alinea con la posición del marcador en el mapa
  popupAnchor: [0, -32],         // Ajusta dónde aparece el popup en relación al ícono
});

// Fix Leaflet icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});


const EstablishmentDetails = () => {
  const { id } = useParams();
  const [establishment, setEstablishment] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDetails, setShowDetails] = useState(false); // Estado para mostrar/ocultar detalles
  const [showContacts, setShowContacts] = useState(false);
  const [primaryContact, setPrimaryContact] = useState(null);

  const hasCoordinates = establishment?.latitude && establishment?.longitude;

  const displayAddress = establishment?.noAddressNumber
    ? `${establishment?.address}, S/N`
    : `${establishment?.address}, ${establishment?.addressNumber}`;

  const googleMapsUrl = hasCoordinates
    ? `https://www.google.com/maps?q=${establishment.latitude},${establishment.longitude}`
    : '#';

  // Fetch establecimiento
  const fetchEstablishment = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/establishments/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEstablishment(response.data);
      setPrimaryContact(response.data.contacts?.find((c) => c.isPrimary) || null);
      setError(null);
    } catch (err) {
      setError('No se pudo cargar la información del establecimiento.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstablishment();
  }, [id]);

  const handleSaveChanges = async (updatedData) => {
    try {
      const token = localStorage.getItem('authToken');
      await api.put(`/api/establishments/${id}`, updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Establecimiento actualizado con éxito');
      setIsEditing(false);
      fetchEstablishment();
    } catch (error) {
      toast.error('Error al actualizar el establecimiento');
    }
  };

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="visits-container">
      <div className="accordion-container">
        <button className="accordion-toggle" onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? 'Ver menos detalles' : 'Ver más detalles'}{' '}
          {showDetails ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {showDetails && (
          <div className="details-content">
            <p><strong>Nombre:</strong> {establishment.name}</p>
            <p><strong>Dirección:</strong> {displayAddress}</p>
            <p><strong>Barrio:</strong> {establishment.neighborhood || 'No disponible'}</p>
            <p><strong>Localidad:</strong> {establishment.city}</p>
            <p><strong>Provincia:</strong> {establishment.province}</p>
            <p><strong>Código Postal:</strong> {establishment.postalCode || 'No disponible'}</p>
            <p><strong>Rubro:</strong> {establishment.industry?.name || 'No disponible'}</p>

            <button
              data-testid="edit-establishment-button"
              onClick={() => setIsEditing(true)}
              className="edit-establishment-button"
            >
              Editar Establecimiento
            </button>
          </div>
        )}
      </div>

      {primaryContact && (
        <div className="primary-contact-card">
          <p><strong>Contacto Principal:</strong></p>
          <p>{primaryContact.name} - {primaryContact.role}</p>
          <div className="contact-links">
            <a href={`tel:${primaryContact.phone}`} className="contact-link">
              <FaPhone className="contact-icon" />
              <span>Llamar</span>
            </a>
            <a href={`https://wa.me/${primaryContact.phone}`} target="_blank" rel="noreferrer" className="contact-link">
              <FaWhatsapp className="contact-icon" />
              <span>WhatsApp</span>
            </a>
          </div>
        </div>
      )}

      <button onClick={() => setShowContacts(true)} className="edit-establishment-button">
        Ver Contactos
      </button>

      <ContactModal
        open={showContacts}
        onClose={() => setShowContacts(false)}
        setPrimaryContact={setPrimaryContact}
        establishmentId={id}
      />

      {/* Mapa */}
      <div className="map-container">
        <h3>Ubicación en el mapa</h3>
        {hasCoordinates ? (
          <>
            <MapContainer center={[establishment.latitude, establishment.longitude]} zoom={15} style={{ height: '300px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; OpenStreetMap contributors'
              />
              <Marker
                position={[establishment.latitude, establishment.longitude]}
                icon={customIcon} // Asigna tu ícono personalizado aquí
              >
                <Popup>
                  {displayAddress} <br /> {establishment.city}, {establishment.province}
                </Popup>
              </Marker>
            </MapContainer>
            <button onClick={() => window.open(googleMapsUrl, '_blank')} className="google-maps-button">
              Ver en Google Maps
            </button>
          </>
        ) : (
          <p>Sin coordenadas disponibles</p>
        )}
      </div>

      {isEditing && (
        <div className="modal-overlay">
          <div className="establishment-form-container">
            <h4>Editar Establecimiento</h4>
            <EstablishmentForm onSubmit={handleSaveChanges} initialData={establishment} />
            <button onClick={() => setIsEditing(false)} >Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EstablishmentDetails;