import React from "react";
import "./WelcomeSection.css"; // Archivo CSS externo
import WelcomeImage from '../../assets/landingPage/food-scientists-illustration.png'; // Importar imagen ilustrativa

const WelcomeSection = () => {
  return (
    <section className="welcome-section">
      <div className="container welcome-section-container ">
        <div className="text-content">
          <h1>
            ¡Bienvenido a <span className="highlight">BioSafeApp</span>!
          </h1>
          <p>
            La herramienta definitiva para gestionar tus visitas y optimizar
            el tiempo de trabajo.
          </p>
          <a href="/signup" className="cta-button">¡Pruébalo Gratis!</a>
        </div>
        <div className="image-content">
          <img src={WelcomeImage} alt="Welcome Illustration" />
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;
