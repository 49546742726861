import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './AddVisitModal.css';
import api from '../../api/axiosConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddVisitModal = ({ onClose, onVisitAdded }) => {
  const [visitType, setVisitType] = useState('Programada');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [establishmentId, setEstablishmentId] = useState('');
  const [establishments, setEstablishments] = useState([]);
  const [periodicVisit, setPeriodicVisit] = useState(null);
  const [removeMonthConfirmation, setRemoveMonthConfirmation] = useState(false);
  const [monthToRemove, setMonthToRemove] = useState('');
  const [visitRate, setVisitRate] = useState(0);
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    const fetchEstablishments = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get('/api/establishments', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEstablishments(response.data);
      } catch (error) {
        console.error('Error al obtener los establecimientos:', error);
        toast.error('Hubo un problema al obtener los establecimientos. Por favor, inténtalo de nuevo.', { position: 'bottom-right', autoClose: 3000 });
      }
    };

    fetchEstablishments();
  }, []);

  useEffect(() => {
    const selectedEstablishment = establishments.find(est => est._id === establishmentId);
    if (selectedEstablishment) {
      setVisitRate(selectedEstablishment.visitRate || 0);
    }
  }, [establishmentId, establishments]);

  useEffect(() => {
    const fetchPeriodicVisit = async () => {
      if (establishmentId) {
        try {
          const token = localStorage.getItem('authToken');
          const response = await api.get(`/api/establishments/${establishmentId}/periodicVisit`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setPeriodicVisit(response.data);
        } catch (error) {
          console.error('Error al obtener la visita periódica:', error);
          setPeriodicVisit(null);
        }
      } else {
        setPeriodicVisit(null);
      }
    };

    fetchPeriodicVisit();
  }, [establishmentId]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!establishmentId) {
      toast.warning('Por favor, selecciona un establecimiento.', { position: 'bottom-right', autoClose: 3000 });
      return;
    }

    const selectedDate = new Date(date);
    if (isNaN(selectedDate.getTime())) {
      toast.error('Fecha inválida, por favor selecciona una fecha válida.', { position: 'bottom-right', autoClose: 3000 });
      return;
    }

    setFormattedDate(selectedDate.toISOString());
    const monthNames = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
    const visitMonthAbbr = monthNames[selectedDate.getMonth()];

    if (periodicVisit && periodicVisit.months.includes(visitMonthAbbr)) {
      setRemoveMonthConfirmation(true);
      setMonthToRemove(visitMonthAbbr);
      return;
    }

    await createVisit(selectedDate.toISOString());
  };

  const createVisit = async (dateToSend) => {
    const newVisit = {
      type: visitType,
      date: dateToSend,
      establishmentId,
      visitRate,
    };

    try {
      const token = localStorage.getItem('authToken');
      await api.post('/api/visits', newVisit, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success('Visita agregada correctamente', { position: 'bottom-right', autoClose: 3000 });

      // Esperar 3 segundos para mostrar el toast antes de cerrar el modal
      setTimeout(() => {
        onVisitAdded(establishmentId);
        onClose();
      }, 3500);
    } catch (error) {
      console.error('Error al agregar la visita:', error);
      toast.error('Hubo un problema al agregar la visita. Por favor, inténtalo de nuevo.', { position: 'bottom-right', autoClose: 3000 });
    }
  };

  const handleConfirmRemoveMonth = async () => {
    const updatedMonths = periodicVisit.months.filter(month => month !== monthToRemove);

    if (updatedMonths.length === 0) {
      try {
        const token = localStorage.getItem('authToken');
        await api.delete(`/api/establishments/${establishmentId}/periodicVisit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPeriodicVisit(null);
      } catch (error) {
        console.error('Error al eliminar la visita periódica:', error);
        toast.error('Hubo un problema al actualizar las visitas periódicas. Por favor, inténtalo de nuevo.', { position: 'bottom-right', autoClose: 3000 });
        return;
      }
    } else {
      try {
        const token = localStorage.getItem('authToken');
        await api.put(
          `/api/establishments/${establishmentId}/periodicVisit`,
          {
            months: updatedMonths,
            dayOfMonth: periodicVisit.dayOfMonth,
            daysBeforeNotification: periodicVisit.daysBeforeNotification,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPeriodicVisit({ ...periodicVisit, months: updatedMonths });
      } catch (error) {
        console.error('Error al actualizar la visita periódica:', error);
        toast.error('Hubo un problema al actualizar las visitas periódicas. Por favor, inténtalo de nuevo.', { position: 'bottom-right', autoClose: 3000 });
        return;
      } finally {
        window.location.reload();
      }
    }

    setRemoveMonthConfirmation(false);
    await createVisit(formattedDate);
  };

  const handleCancelRemoveMonth = () => {
    setRemoveMonthConfirmation(false);
    createVisit(formattedDate);
  };

  const modalContent = (
    // Modal para agregar una visita
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className={`add-visit-modal`}>
        <h2>Agregar Visita</h2>
        {removeMonthConfirmation ? (
          <div>
            <p>Ya tienes una visita programada para este mes ({monthToRemove}).</p>
            <p>¿Deseas eliminar este mes de tus visitas periódicas?</p>
            <div className="modal-buttons">
              <button data-testid="confirm-remove-month-button" onClick={handleConfirmRemoveMonth}>Sí, eliminar mes</button>
              <button data-testid="cancel-remove-month-button" onClick={handleCancelRemoveMonth}>No, mantener visitas</button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              Tipo de Visita:
              <select
                data-testid="visit-type-select"
                value={visitType}
                onChange={(e) => setVisitType(e.target.value)}
              >
                <option value="Programada">Programada</option>
                <option value="No programada">No programada</option>
              </select>
            </label>

            <label>
              Fecha de la Visita:
              <input
                data-testid="visit-date-input"
                type="date"
                name="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </label>

            <label>
              Establecimiento:
              <select
                data-testid="establishment-select"
                value={establishmentId}
                onChange={(e) => setEstablishmentId(e.target.value)}
                required
              >
                <option value="">Selecciona un establecimiento</option>
                {establishments.map((establishment) => (
                  <option key={establishment._id} value={establishment._id}>
                    {establishment.name} | {establishment.address}
                  </option>
                ))}
              </select>
            </label>

            <div className="modal-buttons">
              <button data-testid="save-visit-button" type="submit">Guardar</button>
              <button data-testid="cancel-visit-button" type="button" onClick={onClose}>Cancelar</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById('modal-root')
  );
};

AddVisitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onVisitAdded: PropTypes.func.isRequired,
};

export default AddVisitModal;